(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/navigation-button-user.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/navigation-button-user.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.navigation_button_user = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"userMenu") : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":34,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerIconsOnly") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":33,"column":11}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  "      <button aria-label=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":4,"column":26},"end":{"line":4,"column":87}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" aria-controls=\"navigation-menu-user\" aria-expanded=\"false\" class=\"nav-user js-nav-user icon-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hamburgerIconColor") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":4,"column":188},"end":{"line":4,"column":304}}})) != null ? stack1 : "")
    + "\">\n        ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":5,"column":158}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-hamburger_icon"),depth0,{"name":"components-components-navigation_menu_v2-hamburger_icon","hash":{"icon":"user"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPlayScanInHeader") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":8,"column":15}}})) != null ? stack1 : "")
    + "      </button>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "Logga in";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":4,"column":58},"end":{"line":4,"column":71}}}) : helper)));
},"8":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "fc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"hamburgerIconColor") || (depth0 != null ? lookupProperty(depth0,"hamburgerIconColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hamburgerIconColor","hash":{},"data":data,"loc":{"start":{"line":4,"column":217},"end":{"line":4,"column":239}}}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerClass") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":4,"column":247},"end":{"line":4,"column":297}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "fc-white";
},"13":function(container,depth0,helpers,partials,data) {
    return "fc-black";
},"15":function(container,depth0,helpers,partials,data) {
    return "<span class=\"nav-user-title\">Logga in</span>";
},"17":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<span class=\"nav-user-title data-hj-suppress\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"firstName") || (depth0 != null ? lookupProperty(depth0,"firstName") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"firstName","hash":{},"data":data,"loc":{"start":{"line":5,"column":122},"end":{"line":5,"column":135}}}) : helper)))
    + "</span>";
},"19":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"nav-user-notification js-playscan-msg\"></span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.hooks.blockHelperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = 
  ((stack1 = lookupProperty(helpers,"unless").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"navigation-menu") : depth0)) != null ? lookupProperty(stack1,"hideHeaderButtons") : stack1),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":26,"column":17}}})) != null ? stack1 : "")
    + "      <button aria-label=\"";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(6, data, 0),"data":data,"loc":{"start":{"line":27,"column":26},"end":{"line":27,"column":79}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" aria-controls=\"navigation-menu-user\" aria-expanded=\"false\" class=\"nav-user js-nav-user icon-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hamburgerIconColor") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":27,"column":180},"end":{"line":27,"column":296}}})) != null ? stack1 : "");
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(32, data, 0),"data":data,"loc":{"start":{"line":27,"column":296},"end":{"line":27,"column":366}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n        ";
  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : alias2),(options={"name":"isAnonymous","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":28,"column":8},"end":{"line":28,"column":114}}}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-hamburger_icon"),depth0,{"name":"components-components-navigation_menu_v2-hamburger_icon","hash":{"icon":"user"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showPlayScanInHeader") : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":8},"end":{"line":31,"column":15}}})) != null ? stack1 : "")
    + "      </button>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    }, buffer = "";

  stack1 = ((helper = (helper = lookupProperty(helpers,"isAnonymous") || (depth0 != null ? lookupProperty(depth0,"isAnonymous") : depth0)) != null ? helper : container.hooks.helperMissing),(options={"name":"isAnonymous","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":8},"end":{"line":25,"column":24}}}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!lookupProperty(helpers,"isAnonymous")) { stack1 = container.hooks.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <div class=\"nav-menu-header-buttons visible-md visible-lg visible-xl\">\n            "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(alias1,"button",{"name":"ui","hash":{"href":"/logga-in","class":"nav-menu-header-button","size":"300","focus":true},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":12},"end":{"line":14,"column":113}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"headerButtonClass") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(27, data, 0),"data":data,"loc":{"start":{"line":15,"column":12},"end":{"line":23,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||alias2).call(alias1,"button",{"name":"ui","hash":{"href":"/skapa-inloggning","class":(lookupProperty(helpers,"concat")||(depth0 && lookupProperty(depth0,"concat"))||alias2).call(alias1,"nav-menu-header-button ",(depth0 != null ? lookupProperty(depth0,"headerButtonClass") : depth0),{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":16,"column":78},"end":{"line":16,"column":130}}}),"size":"300","inverted":false,"transparent":true},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":16,"column":14},"end":{"line":16,"column":172}}})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    return "Bli kund";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerButtonsDefaultColor") : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":18,"column":14},"end":{"line":22,"column":21}}})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"href":"/skapa-inloggning","class":"nav-menu-header-button","size":"300","inverted":false,"transparent":true},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":146}}})) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                "
    + ((stack1 = (lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"button",{"name":"ui","hash":{"href":"/skapa-inloggning","class":"nav-menu-header-button","size":"300","inverted":(depth0 != null ? lookupProperty(depth0,"headerClass") : depth0),"transparent":true},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":152}}})) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "";
},"34":function(container,depth0,helpers,partials,data) {
    return " hidden-md hidden-lg hidden-xl";
},"36":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"nav-user-notification js-playscan-msg\"></span>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":35,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-components-navigation_menu_v2-navigation_button_user'] = svs.components.navigation_menu_v2.templates.navigation_button_user;
})(svs, Handlebars);


 })(window);