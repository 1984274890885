(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/assets/javascripts/navigation.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/assets/javascripts/navigation.js');


'use strict';

svs.components = svs.components || {};
svs.components.data = svs.components.data || {};
svs.components.data.navigation = svs.components.data.navigation || {};

var activateItem = function($item, enabled) {
  $item
    .attr('data-active', enabled)
    .attr('data-selected', enabled)
    .attr('data-routed', enabled);
};

var findItemById = function(id, item) {
  if (item.items) {
    var tempItem;
    for (var i = 0, len = item.items.length; i < len; i++) {
      tempItem = findItemById(id, item.items[i]) || {};
      if (id && tempItem.id === id) {
        item.id && (tempItem.parentId = item.id);
        break;
      }
      tempItem = {};
    }
    item = tempItem;
  }
  return item;
};

svs.components.Navigation = function(element) {
  var $el,
    model = { isOpen: false, isUserMenuOpen: false },
    $body = $(document.body),
    $hamburger = $('.js-nav-hamburger'),
    $user = $('.js-nav-user'),
    $mainNav = $('#navigation-menu-main'),
    $userNav = $('#navigation-menu-user'),
    $navTabEnd = $('.js-nav-menu-nav-tab-end'),
    $userTabEnd = $('.js-nav-menu-user-tab-end'),
    hamburgerCallback,
    menu = {},
    drawerLeft,
    drawerRight,
    self = this;

  this.init = function(element) {
    $el = element;
    self.setupListeners();
    self.openMenuOnHash();
    self.checkUserStatus();
    return true;
  };

  this.setHamburgerCallback = function(cb) {
    hamburgerCallback = cb;
  };

  this.restoreHamburgerCallback = function() {
    hamburgerCallback = undefined;
  };

  this.setHamburgerIcon = function(icon) {
    $hamburger.html(
      svs.components.navigation_menu_v2.templates.hamburger_icon({ icon: icon })
    );
  };

  this.setupSwiper = function() {
    if (!this.swiperActive) {
      new svs.components.Swiper().init($el.find('.js-navigation-drawer-left'), {
        scrollbars: false,
        scrollingX: false,
        updateOnWindowResize: true,
        updateOnChanges: true
      });
      new svs.components.Swiper().init($el.find('.js-navigation-drawer-right'), {
        scrollbars: false,
        scrollingX: false,
        updateOnWindowResize: true,
        updateOnChanges: true
      });
      this.swiperActive = true;
    }
  };

  this.findItemTitle = function($item) {
    return $(
      $item
        .find('.nav-list-item-link-title')
        .contents()
        .get(0)
    )
      .text()
      .trim();
  };

  this.createGAEvent = function(e, callback) {
    var $item = $(e.currentTarget);
    if (!e.isTrigger) {
      var gaEvent = {
          category: $item.closest('nav').is($mainNav) ? 'left menu' : 'right menu'
        },
        itemTitle = this.findItemTitle($item),
        parentTitle = this.findItemTitle($item.parents('.nav-list-item'));

      if (parentTitle) {
        gaEvent.action = parentTitle;
        gaEvent.opt_label = itemTitle;
      } else {
        gaEvent.action = itemTitle;
      }
      svs.components.analytics.trackEvent(gaEvent, function() {
        if (callback) {
          callback();
        } else {
          location.href = $item.attr('href');
        }
      });
    }
    e.preventDefault();
    return false;
  };

  this.setupListeners = function() {
    var self = this;

    drawerLeft = new svs.ui.Drawer({ dimmerEl: $el.siblings('.js-nav-menu-nav-dimmer')[0], el: $el.find('.js-nav-menu-nav')[0] });
    drawerRight = new svs.ui.Drawer({ dimmerEl: $el.siblings('.js-nav-menu-user-dimmer')[0], el: $el.find('.js-nav-menu-user')[0], position: 'right' });

    drawerLeft.on('close', function() {
      self.closeMenu();
      $body.addClass('nav-animate-close');
    });

    drawerLeft.on('closed', function() {
      $body.removeClass('nav-animate-close');
    });

    drawerRight.on('close', function() {
      self.closeUserMenu();
      $body.addClass('nav-animate-close');
    });

    drawerRight.on('closed', function() {
      $body.removeClass('nav-animate-close');
    });

    self.setupSwiper();

    if (svs.components.data.navigation.debug) {
      $('.js-nav-debug-toggle-top').on('change', function() {
        if ($body.hasClass('nav-top')) {
          $body.removeClass('nav-top');
        } else {
          $body.addClass('nav-top');
        }
      });
      setInterval(function() {
        var classes = $body.attr('class').replace('wrap', '');
        $('.js-nav-debug-classnames').text(classes);
      }, 500);
    }

    $user.click(function() {
      self.toggleUserMenu();
    });

    $hamburger.click(function() {
      if (hamburgerCallback) {
        hamburgerCallback();
      } else {
        self.toggle();
      }
    });

    $el.on('click', '.nav-list-item-link', function(e) {
      var $item = $(this).parent(),
        isChildMenuItem = Boolean($(this).parents('.nav-submenu').length),
        hasSubmenu = $item.find('.nav-submenu').length,
        wasSelected = $item.attr('data-selected'),
        wasActive = $item.attr('data-active'),
        hasAction = $item.attr('data-action'),
        selectedState = true,
        activeState = true,
        subMenuState = true,
        closeMenu = false;

      if (hasAction) {
        self.createGAEvent(e, function() {
          self.callToAction(hasAction);
        });
        return false;
      }

      if (isChildMenuItem) {
        if (!wasSelected) {
          $('.nav-submenu .nav-list-item[data-selected]')
            .attr('data-active', null)
            .attr('data-selected', null);
        }
      } else {
        $el
          .find('ul')
          .first()
          .children()
          .attr('data-active', null)
          .attr('data-selected', null);
        if (hasSubmenu) {
          if (wasSelected) {
            if (wasActive) {
              selectedState = null;
              activeState = null;
              subMenuState = false;
            }
          }
        } else {
          activeState = null;
          subMenuState = false;
          if (wasSelected) {
            selectedState = null;
          }
        }
      }

      $item
        .attr('data-active', activeState)
        .attr('data-selected', selectedState);

      if (hasSubmenu) {
        $(this).attr('aria-expanded', activeState ? 'true' : 'false');
        $item.find('.nav-submenu .nav-list-item-link').first().focus();
      }

      if (subMenuState) {
        self.openSubmenu();
      } else if (!isChildMenuItem) {
        if (closeMenu) {
          self.closeMenu();
        } else {
          self.closeSubmenu();
        }
      }

      if (hasSubmenu) {
        return false;
      }

      self.createGAEvent(e);
    });

    if (svs.utils.keylistener) {
      this.keyListenerId = svs.utils.keylistener.addKeyboardListener(function(
        event
      ) {
        self.handleKeys(event);
      });
    }

    this.handleKeys = function(event) {
      if (event.keyCode === svs.utils.keylistener.KEY_ESC) {
        self.closeMenu();
        self.closeUserMenu();
      }
    };

    this.setupTabListeners($mainNav, $navTabEnd);
    this.setupTabListeners($userNav, $userTabEnd);
  };

  this.setupTabListeners = function(navEl, navTabEndEl) {
    navEl.on('keydown', function(e) {
      if (!navEl.is(':focus')) {
        return;
      }

      var focusableElements = navEl.find('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])').filter(':visible');

      if (e.keyCode === 9) {
        if (e.shiftKey) {
          focusableElements.last().focus();
        } else {
          focusableElements.first().focus();
        }
        e.preventDefault();
      }
    });

    navTabEndEl.on('focus', function() {
      navEl.focus();
    });
  };

  this.callToAction = function(action) {
    switch (action) {
      case 'openChat':
        svs.components.chat.open();
        break;
      default:
    }
    self.closeMenu();
  };

  this.checkUserStatus = function() {
    var loggedInName;
    if (
      svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL) ||
      svs.core.userSession.hasRole(svs.core.userSession.roles.RETAILER)
    ) {
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL)) {
        loggedInName = svs.components.Storage.browser.get('internalAlias', 'svs.components.login') || 'Admin';
      } else {
        loggedInName = svs.components.Storage.browser.get('alias', 'retailerLogin') || 'Ombud';
      }
      $('.js-nav-list-item-link-user > .nav-list-item-link-title').text(loggedInName);
    }
  };

  this.openMenuOnHash = function() {
    if (location.hash) {
      for (var i = 0, length = svs.components.data.navigation.items.length; i < length; i++) {
        var item = svs.components.data.navigation.items[i];
        if (item.openOnHash) {
          if (location.hash === '#' + item.url.replace(/^[/]/, '')) {
            $('.js-nav-list-item-' + item.id + ' > .nav-list-item-link').trigger('click');
          }
        }
      }
    }
  };

  this.openMenuById = function(id) {
    self.openMenu();
    for (var i = 0, length = svs.components.data.navigation.items.length; i < length; i++) {
      var item = svs.components.data.navigation.items[i];
      if (item.id === id) {
        $('.js-nav-list-item-' + item.id + ' > .nav-list-item-link').trigger('click');
        return true;
      }
    }
  };

  this.setActiveById = function(id) {
    var item = findItemById(id, svs.components.data.navigation);
    if (item.id) {
      var $item = $(
          '.js-nav-list-item-' + item.id + ' > .nav-list-item-link'
        ).parent(),
        $parent = $('.js-nav-list-item-' + item.parentId);
      activateItem($('.nav-submenu .nav-list-item[data-selected]'), null);
      activateItem($item, true);
      activateItem($parent, true);
    }
    return item;
  };

  this.preventDefault = function(e) {
    var target = $(e.target);
    if (target.parents('.js-nav-menu').length === 0) {
      e.preventDefault();
    }
  };

  this.openMenu = function() {
    if (!model.isOpen) {
      model.isOpen = true;
      $hamburger.attr('aria-expanded', 'true');
      drawerLeft.open();
      $body.addClass('nav-open');
      $mainNav.attr('aria-hidden', 'false');
      $mainNav.focus();
    }
  };

  this.closeMenu = function(menuItem) {
    if (menuItem) {
      $(menuItem)
        .attr('data-active', null)
        .attr('data-selected', null);
    } else {
      if (model.isOpen) {
        model.isOpen = false;
        $el
          .find('ul')
          .first()
          .children()
          .attr('data-active', null)
          .attr('data-selected', null);
        $hamburger.attr('aria-expanded', 'false');
        drawerLeft.close();
        $body.removeClass('nav-open');
        $mainNav.attr('aria-hidden', 'true');
        $hamburger.focus();
      }
    }
    $body.removeClass('nav-submenu-open');
  };

  this.openUserMenu = function() {
    if (!model.isUserMenuOpen) {
      model.isUserMenuOpen = true;

      if ($(document).height() > $(window).height()) {
        $body.addClass('nav-has-scrollbar');
      }

      $body.addClass('nav-open');
      drawerRight.open();
      $user.attr('aria-expanded', 'true');
      $userNav.attr('aria-hidden', 'false');
      $userNav.focus();
    }
  };

  this.closeUserMenu = function() {
    if (model.isUserMenuOpen) {
      model.isUserMenuOpen = false;
      $body.removeClass('nav-open nav-has-scrollbar');
      drawerRight.close();
      $user.attr('aria-expanded', 'false');
      $userNav.attr('aria-hidden', 'true');
      $user.focus();
    }
  };

  this.openSubmenu = function() {
    if (!model.isOpen) {
      this.openMenu();
    }
    $body.addClass('nav-submenu-open');
  };

  this.closeSubmenu = function() {
    $('.nav > .nav-list > .nav-list-item[data-active]').attr('data-active', null);
    $body.removeClass('nav-submenu-open');
  };

  this.toggle = function() {
    if (!model.isOpen) {
      self.openMenu();
    } else {
      self.closeMenu();
    }
  };

  this.toggleUserMenu = function() {
    if (!model.isUserMenuOpen) {
      self.openUserMenu();
    } else {
      self.closeUserMenu();
    }
  };

  this.getUserNotifications = function() {
    return false;
  };

  this.hideHamburger = function() {
    if ($hamburger.is(':visible')) {
      $hamburger.hide();
    }
  };

  this.showHamburger = function() {
    if ($hamburger.is(':hidden')) {
      $hamburger.show();
    }
  };

  this.toggleHamburger = function() {
    if ($hamburger.is(':hidden')) {
      this.showHamburger();
    } else {
      this.hideHamburger();
    }
  };

  menu = new svs.core.Events({
    toggle: this.toggle,
    open: this.openMenu,
    close: this.closeMenu,
    openUserMenu: this.openUserMenu,
    closeUserMenu: this.closeUserMenu,
    openMenuById: this.openMenuById,
    setActiveById: this.setActiveById,
    hideHamburger: this.hideHamburger,
    showHamburger: this.showHamburger,
    toggleHamburger: this.toggleHamburger,
    setHamburgerIcon: this.setHamburgerIcon,
    setHamburgerCallback: this.setHamburgerCallback,
    restoreHamburgerCallback: this.restoreHamburgerCallback,
    getUserNotifications: this.getUserNotifications
  });

  self.init(element);

  return menu;
};

svs.components.navigation = new svs.components.Navigation($('.js-nav'));


 })(window);