(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/assets/javascripts/navigation-user.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/assets/javascripts/navigation-user.js');
"use strict";


(function (svs) {
  'use strict';

  svs.components = svs.components || {};
  svs.components.Navigation = svs.components.Navigation || {};


  svs.components.Navigation.User = function () {
    let userMenuOpen = false;
    let showAccountBalance = false;
    const $sessionTimeContainer = $('.js-session-time');
    const $lastLoginTimeContainer = $('.js-last-login-time');
    const $sessionTimeContainerAccountBalanceBar = $('.js-session-time-account-balance-bar');
    function init() {
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        renderLoggedInTime(svs.components.session_time.get());
        renderLastLoginTime();
        svs.core.userSession.get('showMenuAccountBalance', (error, data) => {
          showAccountBalance = Boolean(data);
          getAccountBalance();
        });
      }
      setupListeners();
    }
    function setupListeners() {
      $(document).on('click', '.js-account-logout', logout);
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        $('.js-nav-list-item-login .nav-list-item-link').on('click', e => {
          if (svs.core.userSession.isAnonymous()) {
            svs.components.customer_login.login();
            e.preventDefault();
            return false;
          }
        });
        if ($('.js-account-balance-toggle').length > 0) {
          $('.js-nav-user').on('click', () => {
            svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER) && !userMenuOpen && getAccountBalance();
            userMenuOpen = !userMenuOpen;
          });
          $('.js-account-balance-toggle').on('click', () => {
            showAccountBalance = !showAccountBalance;
            svs.core.userSession.set('showMenuAccountBalance', showAccountBalance, () => {
              if (showAccountBalance) {
                getAccountBalance();
              } else {
                renderAccountBalance();
              }
            });
          });
          svs.components.account_balance.on('change', renderAccountBalance);
        }
        svs.events.subscribe('/components/session_time/sessionTimeM', renderLoggedInTime);
      }
    }

    function getAccountBalance() {
      if (showAccountBalance && svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
        svs.components.account_balance.get(true, renderAccountBalance);
      } else {
        renderAccountBalance();
      }
    }
    function renderLastLoginTime() {
      if (svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER) && $lastLoginTimeContainer.length > 0) {
        const updateLastLoginTime = function (lastLoginTime, err) {
          if (err || lastLoginTime === undefined || lastLoginTime === '') {
            lastLoginTime = formatDate(new Date(), false);
            svs.core.userSession.set('lastLoginTime', lastLoginTime);
          }
          $lastLoginTimeContainer.html("Senaste inloggning <span class=\"navigation-user-header-time-last-time\">".concat(lastLoginTime, "</span>"));
          return lastLoginTime;
        };
        const formatDate = function (inDate, withSeconds) {
          const zeroPad = function (n) {
            return n < 10 ? "0".concat(n) : n;
          };
          try {
            return "".concat(inDate.getFullYear(), "-").concat(zeroPad(inDate.getMonth() + 1), "-").concat(zeroPad(inDate.getDate()), " ").concat(zeroPad(inDate.getHours()), ":").concat(zeroPad(inDate.getMinutes())).concat(withSeconds ? ":".concat(zeroPad(inDate.getSeconds())) : '');
          } catch (e) {
            return '';
          }
        };
        svs.core.userSession.get('lastLoginTime', (error, data) => {
          if (!error) {
            updateLastLoginTime(data);
          }
        });
      }
    }
    function renderLoggedInTime(timesInMinutes) {
      let timeToDisplay = '00:00';
      function getMinString(minutes) {
        return minutes < 10 ? "0".concat(minutes) : minutes;
      }
      if (timesInMinutes < 10 * 60) {
        timeToDisplay = "0".concat(Math.floor(timesInMinutes / 60), ":").concat(getMinString(timesInMinutes % 60));
      } else if (timesInMinutes < 24 * 60) {
        timeToDisplay = "".concat(Math.floor(timesInMinutes / 60), ":").concat(getMinString(timesInMinutes % 60));
      } else if (timesInMinutes < 48 * 60) {
        timeToDisplay = '1 dag';
      } else {
        timeToDisplay = "".concat(Math.ceil(timesInMinutes / (24 * 60)), " dagar");
      }
      $sessionTimeContainer.html("Inloggad tid ".concat(timeToDisplay));
      $sessionTimeContainerAccountBalanceBar.length > 0 && $sessionTimeContainerAccountBalanceBar.html("Inloggad tid: <span class=\"f-bold\">".concat(timeToDisplay, "</span>"));
    }
    function renderAccountBalance(balance) {
      const $container = $('.js-account-balance');
      if (balance === undefined || !showAccountBalance) {
        $container.html('Visa saldo');
      } else {
        $container.html("".concat(svs.utils.format.Currency(balance), " kr"));
      }
    }
    function logout(e) {
      e.preventDefault();

      const evt = {
        category: 'Users',
        action: 'Logout'
      };
      svs.components.analytics.trackEvent(evt);
      svs.core.userSession.logout();
    }
    $(() => {
      init();
    });
  };
  new svs.components.Navigation.User();
})(svs);

 })(window);