(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/hamburger-icon.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/hamburger-icon.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.hamburger_icon = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"ui")||(depth0 && lookupProperty(depth0,"ui"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"icon",{"name":"ui","hash":{"icon":(depth0 != null ? lookupProperty(depth0,"icon") : depth0)},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":23}}}))
    + "\n";
},"useData":true});
Handlebars.partials['components-components-navigation_menu_v2-hamburger_icon'] = svs.components.navigation_menu_v2.templates.hamburger_icon;
})(svs, Handlebars);


 })(window);