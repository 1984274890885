(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/navigation-list.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/navigation-list.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.navigation_list = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-navigation_list_item"),depth0,{"name":"components-components-navigation_menu_v2-navigation_list_item","hash":{"class":"nav-list-column-title","title":lookupProperty(helpers,"lookup").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"columnTitles") : depth0),(depth0 != null ? lookupProperty(depth0,"columnIndex") : depth0),{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":3,"column":94},"end":{"line":3,"column":127}}}),"type":"columnTitle"},"data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"id") : depth0),"===","spacer",{"name":"compare","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.program(6, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":6,"column":8},"end":{"line":10,"column":20}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-navigation_list_item_spacer"),depth0,{"name":"components-components-navigation_menu_v2-navigation_list_item_spacer","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-navigation_list_item"),depth0,{"name":"components-components-navigation_menu_v2-navigation_list_item","hash":{"autoFoldOut":(depths[1] != null ? lookupProperty(depths[1],"autoFoldOut") : depths[1]),"menuLevel":(depths[1] != null ? lookupProperty(depths[1],"menuLevel") : depths[1]),"columnTitles":(depth0 != null ? lookupProperty(depth0,"columnTitles") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<ul class=\"nav-list\" data-level=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"menuLevel") || (depth0 != null ? lookupProperty(depth0,"menuLevel") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"menuLevel","hash":{},"data":data,"loc":{"start":{"line":1,"column":33},"end":{"line":1,"column":46}}}) : helper)))
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"columnTitles") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":9}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"menuItems") : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":11,"column":13}}})) != null ? stack1 : "")
    + "</ul>";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-components-navigation_menu_v2-navigation_list'] = svs.components.navigation_menu_v2.templates.navigation_list;
})(svs, Handlebars);


 })(window);