(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/navigation-list-item-spacer.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/navigation-list-item-spacer.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.navigation_list_item_spacer = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<li class=\"nav-list-item nav-list-item-spacer\"></li>\n";
},"useData":true});
Handlebars.partials['components-components-navigation_menu_v2-navigation_list_item_spacer'] = svs.components.navigation_menu_v2.templates.navigation_list_item_spacer;
})(svs, Handlebars);


 })(window);