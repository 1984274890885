(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/navigation-menu-v2/views/navigation-button-menu.js') >= 0) return;  svs.modules.push('/components/components/navigation-menu-v2/views/navigation-button-menu.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_cmps=_svs.components=_svs.components||{};
_cmps.navigation_menu_v2=_cmps.navigation_menu_v2||{};
_cmps.navigation_menu_v2.templates=_cmps.navigation_menu_v2.templates||{};
svs.components.navigation_menu_v2.templates.navigation_button_menu = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <button aria-label=\"Meny\" aria-controls=\"navigation-menu-main\" aria-expanded=\"false\" class=\"nav-hamburger js-nav-hamburger icon-button "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hamburgerIconColor") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":137},"end":{"line":2,"column":253}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hamburgerIcon") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":7,"column":11}}})) != null ? stack1 : "")
    + "    <span class=\"nav-hamburger-title\">"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hamburgerText") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":8,"column":38},"end":{"line":8,"column":95}}})) != null ? stack1 : "")
    + "</span>\n  </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "fc-"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"hamburgerIconColor") || (depth0 != null ? lookupProperty(depth0,"hamburgerIconColor") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hamburgerIconColor","hash":{},"data":data,"loc":{"start":{"line":2,"column":166},"end":{"line":2,"column":188}}}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"headerClass") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":2,"column":196},"end":{"line":2,"column":246}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "fc-white";
},"7":function(container,depth0,helpers,partials,data) {
    return "fc-black";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-hamburger_icon"),depth0,{"name":"components-components-navigation_menu_v2-hamburger_icon","hash":{"icon":(depth0 != null ? lookupProperty(depth0,"hamburgerIcon") : depth0)},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-components-navigation_menu_v2-hamburger_icon"),depth0,{"name":"components-components-navigation_menu_v2-hamburger_icon","hash":{"icon":"menu"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"hamburgerText") || (depth0 != null ? lookupProperty(depth0,"hamburgerText") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"hamburgerText","hash":{},"data":data,"loc":{"start":{"line":8,"column":59},"end":{"line":8,"column":76}}}) : helper)));
},"15":function(container,depth0,helpers,partials,data) {
    return "Meny";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hide") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":10,"column":11}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});
Handlebars.partials['components-components-navigation_menu_v2-navigation_button_menu'] = svs.components.navigation_menu_v2.templates.navigation_button_menu;
})(svs, Handlebars);


 })(window);